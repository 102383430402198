import Link from '@components/Link';
import logo from '@images/hackcu-logo-new.png';
//import logo from '@images/hackcu-logo.svg';
import React, { HTMLAttributes, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { AnchorLink as CustomLink, AnchorLinkProps } from 'gatsby-plugin-anchor-links';
import clsx from 'clsx';
import '../../../pages/hackcu11/fonts.css';

const AnchorLink: React.FC<AnchorLinkProps & { onClick?: () => void }> = ({
	onClick,
	...props
}) => (
	<div onClick={onClick} className="p-2 sm:py-0">
		<CustomLink className="font-bold text-center font-xl" {...props} />
	</div>
);

const HackCU11Header: React.FC<HTMLAttributes<HTMLHeadingElement>> = (props) => {
	const [open, setOpen] = useState(false);

	const goto = () => {
		console.log('clicked');
		setOpen(false);
	};

	return (
		<header
			className="flex bg-hackcu11-background-500 items-center flex-col md:flex-row fixed left-0 right-0 top-0 py-2"
			style={{ zIndex: 10 }}
			{...props}
		>
			<div className="flex px-4 md:px-0 w-full md:w-auto">
				<Link to="/">
					<div className="ml-0 md:ml-3">
						<img
							className="mr-0 md:mr-4"
							src={logo as string}
							alt="HackCU Logo"
							style={{ height: 60 }}
						/>
					</div>
				</Link>
				{/* TODO: fix weird slide bug when changing from mobile to desktop screen size */}
				<div
					className="flex ml-auto cursor-pointer visible md:invisible"
					onClick={() => setOpen(!open)}
					aria-expanded={open}
					aria-controls="nav-links"
					tabIndex={0}
					role="button"
					aria-label="Navigation trigger"
				>
					<div className="m-auto">
						<IconContext.Provider value={{ size: '2rem' }}>
							<FaBars />
						</IconContext.Provider>
					</div>
				</div>
			</div>

			<div
				className={clsx(
					'flex mt-2 md:mt-0 ml-4 md:ml-auto pr-4 md:pr-3 flex-col md:flex-row overflow-hidden items-center',
					open ? 'max-h-screen' : 'max-h-0',
					'bg-hackcu11-background-500 md:bg-transparent md:max-h-screen',
				)}
				id="nav-links"
				role="navigation"
			>
				<AnchorLink
					to="/hackcu11#home"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					Home
				</AnchorLink>
				<AnchorLink
					to="/hackcu11#about"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					About
				</AnchorLink>
				<AnchorLink
					to="/hackcu11#tracks"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					Tracks
				</AnchorLink>
				<AnchorLink
					to="/hackcu11#faq"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					FAQs
				</AnchorLink>
				<AnchorLink
					to="/hackcu11#schedule"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					Schedule
				</AnchorLink>
				<AnchorLink
					to="/hackcu11#hacker-info"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					Hacker Info
				</AnchorLink>
				<AnchorLink
					to="/hackcu11#prizes"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					Prizes
				</AnchorLink>
				<AnchorLink
					to="/hackcu11#sponsors"
					onClick={goto}
					className="hover:text-hackcu11-primary-500 transition-colors duration-300"
				>
					Sponsors
				</AnchorLink>
				<Link to="/register" outside>
					<button
						className="font-bold text-xl cursor-pointer px-3 py-2 text-white bg-red-700	 rounded-md"
					>
						Register for HackCU 11!
					</button>
				</Link>{' '}
			</div>
		</header>
	);
};

export default HackCU11Header;
