import Link from '@components/Link';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { HTMLAttributes } from 'react';
import SponsorList from '@components/Sponsors/List';
import './fonts.css';
import styles from './custom.module.css';
import clsx from 'clsx';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Footer from '@components/Layout/Footer';
import HackCU11Header from '@components/Layout/Header11';
import FrontPageDesign from '@components/FrontPageDesign';
import skierImage from '../../images/skier.svg';

const HackathonCard: React.FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	children,
	...rest
}) => (
	<div {...rest} className={clsx('rounded-lg shadow-md p-6', className)}>
		{children}
	</div>
);

const IndexPage = (): JSX.Element => {
	const {
		data,
		schedule1,
		schedule2,
		FAQdata,
		sponsorsData: { nodes: sponsors },
	} = useStaticQuery(graphql`
		{
			data: allHackcu11TracksYaml {
				nodes {
					title
					description
				}
			}

			schedule1: allHackcu11Schedule1Yaml {
				nodes {
					time
					event
				}
			}

			schedule2: allHackcu11Schedule2Yaml {
				nodes {
					time: time2
					event: event2
				}
			}

			FAQdata: allHackcu11FaqYaml {
				nodes {
					question
					answer
				}
			}

			sponsorsData: allHackcu11SponsorsYaml {
				nodes {
					name
					url
					image
				}
			}
		}
	`);

	return (
		<>
			<GatsbySeo title="HackCU 11" description="HackCU 11 - Colorado's Largest Annual Hackathon!" />
			<HackCU11Header />

			{/* Title Section */}
			<div className="bg-hackcu11-background-500 text-hackcu11-gray-500 min-h-screen">
				<section className="text-center" id="home" style={{ marginTop: '80px' }}>
					<FrontPageDesign />
					<h2 className="text-hackcu11-gray-500 text-2xl mt-4 mb-7 tracking-wider sm:text-3xl md:text-4xl">
						March 1st - 2nd, 2025
					</h2>
				</section>

				{/* Main Content */}
				<div className="w-full bg-hackcu11-background-500">
					<div className="mx-auto max-w-7xl px-3 xl:px-0">
						<div className="space-y-8 pt-1">
							{/* Combined About Section */}
							<HackathonCard className="bg-hackcu11-secondary-500 text-white">
								<h1 className="text-center font-bold text-5xl mb-8" id="about">
									About
								</h1>
								<p className="text-left mb-8">
									Join us on March 1st and 2nd, 2025, for our flagship annual hackathon, HackCU 11!
									This is your chance to innovate and create projects across various exciting
									tracks. Whether you&#39;re a seasoned coder or a curious beginner, you&#39;ll find
									workshops and seminars tailored for you. Submit your project to win special prizes
									and gain invaluable experience. HackCU 11 will be held at the Jennie Smoly
									Caruthers Biotechnology Building on CU Boulder East Campus. Space is limited, so
									be sure to register soon!
								</p>

								<div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
									<div>
										<h2 className="text-center font-semibold text-3xl mb-4">Our Story</h2>
										<p className="text-left mb-8">
											HackCU was started in 2014 by a group of passionate students who wanted to
											provide other students the time and space to work on projects they don&#39;t
											normally work on through school. Since then we&#39;ve been committed to
											fulfilling our mission of bringing you high-quality, inclusive events open to
											people of all levels of experience.
										</p>
									</div>
									<div>
										<h2 className="text-center font-semibold text-3xl mb-4">Why You?</h2>
										<p className="text-left mb-8">
											Like to write programs? Dig into interesting datasets? Design creative works?
											Or maybe you&#39;ve never written a line of code? We will provide the space
											and the resources for you to express your creativity, ingenuity, and
											determination to create anything you want to. Whether you&#39;re building your
											first website, dabbling in Photoshop, or hacking with an Oculus Rift, HackCU
											is for you. HackCU is for people of all backgrounds and experiences. So, come
											and join us!
										</p>
									</div>
								</div>
							</HackathonCard>

							{/* Tracks Section */}
							<HackathonCard className="relative bg-white text-hackcu11-secondary-500 mt-16">
								<h1 className="text-center font-bold text-5xl mb-10" id="tracks">
									Tracks
								</h1>
								<div className="relative mt-12 space-y-20">
									{/* Slalom Flags and Cards */}
									{data.nodes.map(({ title, description }, index) => (
										<div
											key={title}
											className="flex items-center justify-between space-x-8 sm:space-x-12"
											style={{
												transform: `translateX(${index % 2 === 0 ? '5%' : '-5%'})`,
												overflowX: 'hidden',
											}}
										>
											{index % 2 !== 0 && (
												<img
													src={skierImage as string}
													alt="Skier"
													className="skier-image skier-left"
													style={{
														width: '20vw',
														maxWidth: '220px',
														height: 'auto',
														transform: 'translateX(20%)',
														marginLeft: '1%',
														marginRight: '1%',
													}}
												/>
											)}
											{index % 2 !== 0 && (
												<div
													className="flex items-center flag-container"
													style={{ width: '5vw', marginLeft: '5%', marginRight: '1%' }}
												>
													<div className="w-1 h-20 bg-gray-700"></div>
													<div
														className={`w-10 h-8 relative ${
															index === 0 || index === 2 ? 'bg-red-700' : 'bg-hackcu11-primary-500'
														}`}
														style={{
															clipPath: 'rectangle(0 0, 100% 100%)',
															top: '-50%',
														}}
													></div>
													<div className="w-1 h-20 bg-gray-700"></div>
												</div>
											)}

											{/* Track Card */}
											<div
												className="p-10 bg-hackcu11-gray-500 shadow-md rounded-md w-full sm:w-4/5 track-card"
												style={{ margin: '0.5% 1%' }}
											>
												<h2 className="font-bold text-3xl text-center mb-3">{title}</h2>
												{description.startsWith('function') ? (
													<MDXRenderer>{description}</MDXRenderer>
												) : (
													<p className="text-xl text-center">{description}</p>
												)}
											</div>

											{index % 2 === 0 && (
												<div
													className="flex items-center flag-container"
													style={{ width: '5vw', marginLeft: '2.2%', marginRight: '3.5%' }}
												>
													<div className="w-1 h-20 bg-gray-700"></div>
													<div
														className={`w-10 h-8 relative ${
															index === 0 || index === 2 ? 'bg-red-700' : 'bg-hackcu11-primary-500'
														}`}
														style={{
															clipPath: 'rectangle(0 0, 100% 100%)',
															top: '-50%',
														}}
													></div>
													<div className="w-1 h-20 bg-gray-700"></div>
												</div>
											)}
											{index % 2 === 0 && (
												<img
													src={skierImage as string}
													alt="Skier"
													className="skier-image skier-right"
													style={{
														width: '20vw',
														maxWidth: '220px',
														height: 'auto',
														transform: 'scaleX(-1) translateX(20%)',
														marginLeft: '1%',
														marginRight: '1%',
													}}
												/>
											)}
										</div>
									))}
								</div>
							</HackathonCard>

							{/* FAQs Section */}
							<HackathonCard className={clsx(styles.card, 'bg-hackcu11-secondary-500 text-white')}>
								<h1 className="text-center font-bold text-5xl mb-8" id="faq">
									FAQs
								</h1>
								<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
									{FAQdata.nodes.map(({ question, answer }) => (
										<div key={question} className="border border-white p-8 rounded-lg shadow-xl">
											<h2 className="font-semibold text-2xl mb-4 flex justify-center items-center text-center">
												<span className="text-sm mr-2">❄️</span> {question}{' '}
												<span className="text-sm ml-2">❄️</span>
											</h2>
											<p className="text-lg leading-relaxed text-left">{answer}</p>
										</div>
									))}
								</div>
							</HackathonCard>

							{/* Schedule Section */}
							<HackathonCard
								className={clsx(styles.card, 'bg-hackcu11-gray-500 text-hackcu11-secondary-500')}
							>
								<h1 className="text-center font-bold text-5xl" id="schedule">
									Schedule
								</h1>
								<div>
									Will be posted here very soon!
								</div>
								{/* 
								<div className="flex flex-row justify-center">
									<div className="mx-3 flex-grow">
										<h1 className="font-bold text-3xl text-center mb-3">Day 1</h1>
										<table className="w-full border-collapse text-center">
											<thead className="text-md">
												<tr>
													<th>Time</th>
													<th>Event</th>
												</tr>
											</thead>
											<tbody>
												{schedule1.nodes.map(({ time, event }, index) => (
													<tr
														key={event}
														style={{
															borderTop: index === 0 ? '2px solid black' : '1px solid black',
														}}
													>
														<td className="p-1">{time}</td>
														<td className="p-1">
															{event.startsWith('function') ? (
																<MDXRenderer>{event}</MDXRenderer>
															) : (
																<p>{event}</p>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
									<div className="mx-3 flex-grow">
										<h1 className="font-bold text-3xl text-center mb-3">Day 2</h1>
										<table className="w-full border-collapse text-center">
											<thead className="text-md">
												<tr>
													<th>Time</th>
													<th>Event</th>
												</tr>
											</thead>
											<tbody>
												{schedule2.nodes.map(({ time, event }, index) => (
													<tr
														key={event}
														style={{
															borderTop: index === 0 ? '2px solid black' : '1px solid black',
														}}
													>
														<td className="p-1">{time}</td>
														<td className="p-1">
															{event.startsWith('function') ? (
																<MDXRenderer>{event}</MDXRenderer>
															) : (
																<p>{event}</p>
															)}
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div> */}
							</HackathonCard>

							{/* Hacker Info Section */}
							<HackathonCard className="bg-hackcu11-secondary-500 text-white">
								<h1 className="text-center font-bold text-5xl mb-8" id="hacker-info">
									Hacker Info
								</h1>
								<div>
									Will be posted here very soon!
								</div>
								{/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
									<div>
										<h2 className="text-center font-semibold text-3xl mb-4">Hacker Guide</h2>
										<p className="text-center mb-8">
											You can find the Hacker Guide here:{' '}
											<a href="https://tinyurl.com/yr4794yy" className="text-white underline">
												https://tinyurl.com/yr4794yy
											</a>
											.
										</p>
									</div>
									<div>
										<h2 className="text-center font-semibold text-3xl mb-4">Project Submission</h2>
										<p className="text-center mb-8">
											We will be using DevPost to submit your projects. You can find the link here:{' '}
											<a href="https://hackcu-10.devpost.com/" className="text-white underline">
												https://hackcu-10.devpost.com/
											</a>
											.
										</p>
									</div>
								</div> */}
							</HackathonCard>

							{/* Prizes Section */}
							<HackathonCard className="bg-white text-hackcu11-secondary-500">
								<h1 className="text-center font-bold text-5xl mb-8" id="prizes">
									Prizes
								</h1>
								<div>
									Will be posted here very soon!
								</div>
								{/* <div className="flex flex-wrap justify-center gap-8">
									<div className="flex flex-wrap justify-center gap-8">
										<div className="bg-gray-200 p-6 rounded-lg shadow-md w-64">
											<h2 className="font-semibold text-xl mb-4 text-center">
												General - 1st Place
											</h2>
											<ul className="list-disc pl-5">
												<li>Power Bank</li>
												<li>Backpacks</li>
												<li>Portable Projector</li>
												<li>Echo Dot 5th Gen</li>
											</ul>
										</div>
										<div className="bg-gray-200 p-6 rounded-lg shadow-md w-64">
											<h2 className="font-semibold text-xl mb-4 text-center">
												General - 2nd Place
											</h2>
											<ul className="list-disc pl-5">
												<li>Large Rocketbook Notebook</li>
												<li>Color Changing LED Bulbs</li>
											</ul>
										</div>
									</div>
									<div className="flex flex-wrap justify-center gap-8">
										<div className="bg-gray-200 p-6 rounded-lg shadow-md w-64">
											<h2 className="font-semibold text-xl mb-4 text-center">
												Beginner - 1st Place
											</h2>
											<ul className="list-disc pl-5">
												<li>Black 20oz Hydroflask</li>
												<li>Cracking the Coding Interview Book</li>
											</ul>
										</div>
										<div className="bg-gray-200 p-6 rounded-lg shadow-md w-64">
											<h2 className="font-semibold text-xl mb-4 text-center">UI/UX - 1st Place</h2>
											<ul className="list-disc pl-5">
												<li>Polaroid Camera</li>
												<li>Polaroid Film</li>
											</ul>
										</div>
										<div className="bg-gray-200 p-6 rounded-lg shadow-md w-64">
											<h2 className="font-semibold text-xl mb-4 text-center">Games - 1st Place</h2>
											<ul className="list-disc pl-5">
												<li>Keyboard and Mouse</li>
											</ul>
										</div>
									</div>
								</div> */}
							</HackathonCard>

							{/* Sponsors Section */}
							<HackathonCard className={clsx(styles.card, 'bg-hackcu11-secondary-500 text-white')}>
								<h1 className="text-center font-bold text-5xl" id="sponsors">
									Sponsors
								</h1>
								<SponsorList>
									{sponsors.map(({ name, url, image }) => (
										<div key={name} className="inline-block p-4">
											<Link outside to={url}>
												<img
													src={require(`../../../content/sponsors${image.substring(1)}`)}
													alt={`${name} logo`}
													className="w-full h-auto object-contain"
												/>
											</Link>
										</div>
									))}
								</SponsorList>
							</HackathonCard>

							{/* Footer */}
							<Footer />
						</div>
					</div>
				</div>

				{/* </div> */}
			</div>
		</>
	);
};

export default IndexPage;
