import React from 'react';
import { motion } from 'framer-motion';
import MountainSVG from '../../images/HackCU11FrontpageBackground.svg';
import SkierSVG from '../../images/HackCU11Skier.svg';

export default function FrontPageDesign() {
	return (
		<>
			<style>{`
				@font-face {
					font-family: 'Snow Kei';
					src: url('../../fonts/Snow Kei.ttf') format('truetype');
				}

				.hackcu-text {
					font-family: 'Snow Kei', sans-serif;
				}
			`}</style>

			<div
				style={{
					position: 'relative', // Establish a positioning context
					width: '100%',
					overflow: 'hidden', // Keeps the skier within bounds
					marginTop: '-4px', // Adjust to remove gap between navbar and design
				}}
			>
				{/* Animated Skier */}
				<motion.div
					style={{
						position: 'absolute',
						width: '100%',
					}}
					initial={{ top: '-90%', scale: 0.01, opacity: 0.1 }}
					animate={{ top: '-50%', scale: 0.2, opacity: 1 }}
					transition={{ duration: 2, ease: 'easeInOut' }}
				>
					<img src={SkierSVG as string} width={'100%'} alt="Skier" />
				</motion.div>

				{/* Animated "HackCU" text */}
				<motion.div
					className="hackcu-text"
					style={{
						position: 'absolute',
						width: '100%',
						fontSize: '10vw',
					}}
					initial={{ top: '-2%', opacity: 0 }}
					animate={{ top: '0%', opacity: 1 }}
					transition={{ duration: 2, ease: 'easeInOut', delay: 3 }}
				>
					<p>HackCU</p>
				</motion.div>

				{/* Animated "1" text */}
				<motion.div
					style={{
						position: 'absolute',
						width: '100%',
						fontSize: '5vw',
						scale: 3,
						background: 'linear-gradient(to bottom, #3B9AE1, #26547C)',
						WebkitBackgroundClip: 'text', // Safari and older Chrome
						WebkitTextFillColor: 'transparent', // Safari and older Chrome
						backgroundClip: 'text', // Standard property for other browsers
						color: 'transparent', // Standard fallback for full compatibility
						textShadow: '0 0 20px rgba(173, 216, 230, 0.9), 0 0 40px rgba(255, 255, 255, 1)',
						fontWeight: 'bold', // Make text bolder
						letterSpacing: '0.1em', // Add space between the ones
					}}
					animate={{
						rotateZ: [-17, -17, 0],
						top: ['65%', '65%', '30%'], // Included initial state in the first keyframe
						left: ['-7%', '-7%', '-4%'], // Included initial state in the first keyframe
						opacity: [0, 1, 1], // Included initial opacity in keyframes
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						// times: [0, 0.5, 1], // Keyframe distribution for all properties
						delay: 2.5,
					}}
				>
					<p>1</p>
				</motion.div>

				<motion.div
					style={{
						position: 'absolute',
						width: '100%',
						fontSize: '5vw',
						scale: 3,
						background: 'linear-gradient(to bottom, #3B9AE1, #26547C)',
						WebkitBackgroundClip: 'text', // Safari and older Chrome
						WebkitTextFillColor: 'transparent', // Safari and older Chrome
						backgroundClip: 'text', // Standard property for other browsers
						color: 'transparent', // Standard fallback for full compatibility
						textShadow: '0 0 20px rgba(173, 216, 230, 0.9), 0 0 40px rgba(255, 255, 255, 1)',
						fontWeight: 'bold', // Make text bolder
						letterSpacing: '-0.02em', // Add space between the ones
					}}
					animate={{
						rotateZ: [17, 17, 0],
						top: ['65%', '65%', '30%'], // Adjusted to end closer to the middle of the page
						left: ['5%', '5%', '2%'], // Included initial state in the first keyframe
						opacity: [0, 1, 1], // Included initial opacity in keyframes
					}}
					transition={{
						duration: 2,
						ease: 'easeInOut',
						// times: [0, 0.5, 1], // Keyframe distribution for all properties
						delay: 2.5,
					}}
				>
					<p>1</p>
				</motion.div>

				{/* Background Mountain */}
				<div
					style={{
						width: '100%',
					}}
				>
					<img src={MountainSVG as string} alt="Mountain" />
				</div>
			</div>
		</>
	);
}
